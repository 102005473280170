import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import Loader from '../Loader';
import axios from 'axios';

const RestaurantDetailPage = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`http://5.78.109.151:7000/common/restaurant/getRestaurant/${id}`);
                setData(response.data);
            } catch (error) {
                message.error('Failed to fetch restaurant details.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (loading) {
        return <Loader />;
    }

    if (!data) {
        return <div>No restaurant data found.</div>;
    }

    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <h2 className="h1 mb-0">Restaurant Detail Page</h2>

                <div className="card mt-2 p-4">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className="title-color">Owner Name:</label>
                                <p>{data.ownerName}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Restaurant Name:</label>
                                <p>{data.restaurantName}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Phone Number:</label>
                                <p>{data.restaurantPhoneNumber}</p>
                            </div>


                            <div className="form-group">
                                <div className="title-color mb-3">Restaurant Logo:</div>
                            <img style={{width:"200px"}} src={data.restaurantLogo} alt={data.restaurantName} />
                            </div>
                           



                            <div className="form-group">
                                <label className="title-color">Address:</label>
                                <p>{data.restaurantAddress}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">City:</label>
                                <p>{data.restaurantCity}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Longitude:</label>
                                <p>{data.restaurantLongitude}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Latitude:</label>
                                <p>{data.restaurantLatitude}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Available Status:</label>
                                <p>{data.availableStatus ? 'Available' : 'Not Available'}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Subscription Status:</label>
                                <p>{data.subscriptionStatus ? 'Active' : 'Inactive'}</p>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className="title-color">Account Number:</label>
                                <p>{data.accountNumber}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Account Title:</label>
                                <p>{data.accountTitle}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Account Bank:</label>
                                <p>{data.accountBank}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Contract File:</label>
                                <a href={data.contractFile} target="_blank" rel="noopener noreferrer">View Contract</a>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Payment Status:</label>
                                <p>{data.paymentStatus ? 'Paid' : 'Not Paid'}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Discount Offering Percentage:</label>
                                <p>{data.discountOfferingPercentage || 0}%</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Discount Active:</label>
                                <p>{data.discountActive ? 'Active' : 'Inactive'}</p>
                            </div>

                            {data.discountActive && (
                                <>
                                    <div className="form-group">
                                        <label className="title-color">Discount Start:</label>
                                        <p>{new Date(data.discountStart).toLocaleDateString()}</p>
                                    </div>

                                    <div className="form-group">
                                        <label className="title-color">Discount End:</label>
                                        <p>{new Date(data.discountEnd).toLocaleDateString()}</p>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RestaurantDetailPage;
