import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../Loader';

const RestaurantCostFeeUpdateForm = () => {
    const { id } = useParams();  
    const navigate = useNavigate();
    const [cost, setCost] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchFee = async () => {
            try {
                const response = await axios.get(`http://5.78.109.151:7000/common/restaurantFee/${id}`);
                setCost(response.data.cost);  
            } catch (error) {
                console.error('Error fetching fee:', error);
                message.error('Failed to load fee data for update.');
            }
        };
        fetchFee();
    }, [id]);  

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!cost || isNaN(cost) || cost <= 0) {
            message.error('Please enter a valid cost greater than 0');
            return;
        }

        try {
            setLoading(true);

            const response = await axios.put(`http://5.78.109.151:7000/common/restaurantFee/${id}`, { cost });

            if (response.status === 200) {
                message.success('Restaurant fee updated successfully!');
                navigate('/resturant_fee'); 
            }
        } catch (error) {
            console.error('Error updating fee:', error);
            message.error('Failed to update restaurant fee. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="mt-5 toppp">
            <div className="content container-fluid mt-5">
                <h2 className="h1 mb-0 d-flex gap-10">
                    <img
                        src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png"
                        alt="Brand logo"
                    />
                    Update Restaurant Fee
                </h2>

                <form onSubmit={handleSubmit}>
                    <div className="card mt-2 p-4">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group lang_form" id="cost-form">
                                    <label className="title-color">
                                        Restaurant Fee Cost<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        name="cost"
                                        id="cost"
                                        className="form-control"
                                        placeholder="Enter updated fee cost"
                                        required=""
                                        value={cost}
                                        onChange={(e) => setCost(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <button type="reset" id="reset" className="btn btn-secondary">
                                Reset
                            </button>
                            <button type="submit" className="btn btn-primary">
                                {loading ? <Loader /> : 'Update Fee'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RestaurantCostFeeUpdateForm;
