import logo from './logo.svg';
import './App.css';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import OutRoutes from './pages/OutRoutes';
import Dashboard from './pages/Dashboard';
import NewProducts from './pages/sellerProducts/NewProducts';
import ApprovedProducts from './pages/sellerProducts/ApprovedProducts';
import DeniedProducts from './pages/sellerProducts/DeniedProducts';
import Category from './pages/category/Category';
import SubCategory from './pages/category/SubCategory';
import SubSubCategory from './pages/category/SubSubCategory';
import Products from './pages/inhouseProducts/Products';
import Banners from './pages/banners/Banners';
import FlashDeal from './pages/deals/FlashDeal';
import DealDay from './pages/deals/DealDay';
import FeaturedDeal from './pages/deals/FeaturedDeal';
import CustomerList from './pages/customer/CustomerList';
import SellerList from './pages/sellers/SellerList';
import ShippingCharges from './pages/shipping/ShippingCharges';
import UpdateCategorey from './pages/UpdateCategory/UpdateCategory';
import UpdateSubCate from './pages/UpdateCategory/UpdateSubCate';
import UpdateSubSubCategory from './pages/UpdateCategory/UpdateSubSubCate'
import UpdateCoupon from './pages/CouponUpdate/CouponUpdate';
import UpdateSubscription from './pages/SubscriptionUpdate/UpdateSubscription';
import Bought_voucher from './pages/deals/BoughtVouchers';
import Bought_subscription from './pages/deals/BoughtSubscriptions';
import BussinessApprovals from './pages/banners/BussinessApprovals';
import Users from './pages/users/Users';
import Food from './pages/Food/Food';
import Uploadplans from './pages/Plans/Uploadplans';
import Allplans from './pages/Plans/Allplans';
import RestaurantDetailPage from './pages/details/Sellerdetail';
import RiderDetailPage from './pages/details/riderdetail';
import Orders from './pages/Orders/Orders';
import PaymentDisplay from './pages/Payments/OrderPayment';
import FeaturedBoughtDisplay from './pages/Payments/FeaturedPament';
import RestaurantFeeDisplay from './pages/Payments/resturantpayment';
import Coupons from './pages/coupons/coupons';
import RestaurantCostForm from './pages/Payments/Resturanfee';
import RestaurantCostFeeUpdateForm from './pages/Payments/Updatefee';
import Expenses from './pages/expense/Expense';
import ChatBar from './pages/chat/Chat';



function  App() {
  return (
    <div className="App">
      <HashRouter>
      <Routes >
        {/* <Route path='/' element={<Login/>} /> */}
        <Route element={<OutRoutes/>}>
          <Route path='/' element={<Dashboard/>}/>
          <Route path='/sellers' element={<NewProducts/>}/>
          <Route path='/approvedSellerProduct' element={<ApprovedProducts/>}/>
          <Route path='/advertisement_approval' element={<DeniedProducts/>}/>
          <Route path='/food' element={<Food/>}/> 
          <Route path='/category' element={<Category/>}/>
          <Route path='/subCategory' element={<SubCategory/>}/>
          <Route path='/all_categories' element={<SubSubCategory/>}/>
          <Route path='/UpdateCategorey/:id' element={<UpdateCategorey/>}/>
          <Route path='/UpdateSubCate/:id' element={<UpdateSubCate/>}/>
          <Route path='/UpdateSubSubCategorey/:id' element={<UpdateSubSubCategory/>}/>
          <Route path='/updateCoupon/:id' element={<UpdateCoupon/>}/>
          <Route path='/seller_verification' element={<Products/>}/>
          <Route path='/Featured_plans' element={<Uploadplans/>}/>
          <Route path='/All_featured' element={<Allplans/>} />



          <Route path='/deals' element={<Banners/>}/>
          <Route path='/deals_approvals' element={<BussinessApprovals/>} />
          

          <Route path='/sellerdetails/:id' element={<RestaurantDetailPage/>} />
          <Route path='/riderdetail/:id' element={<RiderDetailPage/>} />
          <Route path='/updateRestaurantFee/:id' element={<RestaurantCostFeeUpdateForm/>} />

          <Route path='/withdraws' element={<FlashDeal/>}/>
          <Route path='/withdraws_approval' element={<Bought_voucher/>}/>
          <Route path='/featuredDeal' element={<FeaturedDeal/>}/>
          <Route path='/dealDay' element={<DealDay/>}/>


          <Route path='/verification' element={<FeaturedDeal/>}/>
          <Route path='/updatesubscription/:id' element={<UpdateSubscription/>}/>
          <Route path='/delieveryboys' element={<Bought_subscription/>}/>


          <Route path='/sellerList' element={<SellerList/>}/>
          <Route path='/users' element={<Users/>}/>
          <Route path='/coupon' element={<Coupons/>} />
          <Route path='/shippingCharges' element={<ShippingCharges/>}/>
          <Route path='/orders' element={<Orders/>} />
          <Route path='/order_payments' element={<PaymentDisplay/>} />
          <Route path='/featured_payments' element={<FeaturedBoughtDisplay/>} />
          <Route path='/resturant_payments' element={<RestaurantFeeDisplay/>} />
          <Route path='/resturant_fee' element={<RestaurantCostForm/>} />
          <Route path='/expense' element={<Expenses/>}/>
          <Route path='/chat' element={<ChatBar/>} />
        </Route>
      </Routes>
      
      </HashRouter>
    </div>
  );
}

export default App;
