import React, { useState, useEffect } from 'react';
import { Button, Input, List, Spin, Select, notification } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import axios from 'axios';
import { io } from 'socket.io-client';

const ChatBar = () => {
  const [role, setRole] = useState('rider');
  const [selectedUser, setSelectedUser] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [senders, setSenders] = useState([]);
  const [sidebarLoading, setSidebarLoading] = useState(false); // New state for sidebar loading

  const userId = 'admin';
  const socket = io('http://5.78.109.151:7000/message');

  useEffect(() => {
    socket.on('connect', () => {
      socket.emit('joinRoom', { userId });
      socket.emit('fetchMessages', {
        userId: userId,
        userRole: 'admin',
        otherUserId: selectedUser ? selectedUser._id : null,
        otherUserRole: role,
      });

      socket.on('chatHistory', (chatHistory) => {
        setMessages(chatHistory);
      });

      socket.on('receiveMessage', (newMessage) => {
        if (newMessage.receiverRole === role || newMessage.senderRole === role) {
          setMessages((prevMessages) => [...prevMessages, newMessage]);
        }
      });
    });

    return () => {
      socket.off('connect');
      socket.off('chatHistory');
      socket.off('receiveMessage');
    };
  }, [selectedUser, role]);

  const fetchSenders = async (role) => {
    try {
      setSidebarLoading(true); // Set loading to true when starting to fetch data
      const response = await axios.get(`http://5.78.109.151:7000/common/support/admin/senders/${role}`);
      setSenders(response.data);
      setSidebarLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error('Error fetching senders:', error);
      setSidebarLoading(false); // Set loading to false if there's an error
    }
  };

  const handleRoleChange = (value) => {
    setRole(value);
    setSelectedUser(null);
    setMessages([]); 
    fetchSenders(value);
  };

  const handleMessageChange = (e) => {
    setMessageText(e.target.value);
  };

  const sendMessage = () => {
    if (!messageText.trim()) {
      return;
    }

    const message = {
      senderId: userId,
      senderRole: 'admin',
      receiverId: selectedUser._id,
      receiverRole: role,
      message: messageText,
      name: 'admin',
      timestamp: new Date().toLocaleTimeString(), 
    };

    setMessages((prevMessages) => [...prevMessages, message]);
    setLoading(true); 

    socket.emit('sendMessage', message);

    socket.on('messageSent', (sentMessage) => {
      setLoading(false);
      setMessageText(''); 
    });

    socket.on('receiveMessage', (newMessage) => {
      console.log('New message received:', newMessage);
      if (newMessage.receiverRole === role || newMessage.senderRole === role) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    });
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setMessages([]);  
  };

  useEffect(() => {
    fetchSenders(role);
  }, [role]);

  return (
    <div style={{ height: '100vh', padding: '20px', marginTop: '50px' }}>
      <div
        className="topbar"
        style={{
          width: '100%',
          padding: '10px',
          background: '#f5f5f5',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h4 style={{ margin: 0 }}>
          {role === 'user'
            ? 'Chat with Users'
            : role === 'rider'
            ? 'Chat with Riders'
            : 'Chat with Sellers'}
        </h4>
        <Select defaultValue="rider" style={{ width: 120 }} onChange={handleRoleChange}>
          <Select.Option value="rider">Rider</Select.Option>
          <Select.Option value="seller">Seller</Select.Option>
        </Select>
      </div>

      <div className="chat-container" style={{ display: 'flex' }}>
        {/* Sidebar */}
        <div
          className="sidebar"
          style={{
            width: '250px',
            borderRight: '1px solid #ddd',
            padding: '20px',
            background: '#f9f9f9',
          }}
        >
          <h4>{role === 'user' ? 'Users' : role === 'rider' ? 'Riders' : 'Sellers'}</h4>
          {sidebarLoading ? ( // Show loader when fetching senders
            <Spin size="small" />
          ) : (
            <List
              dataSource={senders}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  onClick={() => handleUserSelect(item)}
                  style={{
                    cursor: 'pointer',
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: '5px',
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Avatar>{item?.name?.charAt(0)}</Avatar>
                  {item?.name}
                </List.Item>
              )}
            />
          )}
        </div>

        {/* Main Chat Area */}
        <div
          className="chat-area"
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            marginTop: '20px',
          }}
        >
          <div className="chat-header" style={{ padding: '20px', background: '#f5f5f5', marginBottom: '20px' }}>
            <h4 style={{ margin: 0 }}>
            {selectedUser? 'Chat with' + ' ' +selectedUser?.name:role}
            </h4>
          </div>

          <div
            className="chat-display"
            style={{
              flex: 1,
              padding: '20px',
              overflowY: 'auto',
              background: '#f9f9f9',
              marginBottom: '20px',
              borderRadius: '8px',
              boxShadow: '0 0 5px rgba(0,0,0,0.1)',
            }}
          >
            {selectedUser ? (
              messages.length > 0 ? (
                messages.map((msg, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: '10px',
                      display: 'flex',
                      flexDirection: msg.senderRole === 'admin' ? 'row-reverse' : 'row', 
                      justifyContent: msg.senderRole === 'admin' ? 'start' : 'end',
                    }}
                  >
                    <div
                      style={{
                        maxWidth: '70%', 
                        backgroundColor: msg.senderRole === 'admin' ? '#007bff' : '#e0e0e0', 
                        color: msg.senderRole === 'admin' ? '#fff' : '#000',
                        padding: '10px',
                        borderRadius: '10px',
                        wordWrap: 'break-word',
                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <strong>{msg.senderRole}:</strong> {msg.message} <small>{msg.timestamp}</small>
                    </div>
                  </div>
                ))
              ) : (
                <Spin size="small" />
              )
            ) : (
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <p>No chat selected</p>
              </div>
            )}
          </div>

          {/* Message Input Box */}
          <div
            className="message-input"
            style={{
              padding: '20px',
              borderTop: '1px solid #ddd',
              background: '#f5f5f5',
              height: '80px',
            }}
          >
            <Input
              value={messageText}
              onChange={handleMessageChange}
              placeholder="Type a message"
              style={{ width: '80%', marginRight: '10px' }}
            />
            <Button
              type="primary"
              icon={<SendOutlined />}
              onClick={sendMessage}
              loading={loading}
              disabled={!selectedUser}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBar;
