import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../Loader';
import axios from 'axios';
import { message } from 'antd';

const RiderDetailPage = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`http://5.78.109.151:7000/common/rider/${id}`);
                setData(response.data);
                console.log(response)
            } catch (error) {
                message.error('Failed to fetch details.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (loading) {
        return <Loader />;
    }

    if (!data) {
        return <div>No data found.</div>;
    }

    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <h2 className="h1 mb-0">Detail Page</h2>

                <div className="card mt-2 p-4">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className="title-color">First Name:</label>
                                <p>{data?.firstName}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Last Name:</label>
                                <p>{data?.lastName}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Email:</label>
                                <p>{data?.email}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Phone Number:</label>
                                <p>{data?.phoneNumber}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Pakistani:</label>
                                <p>{data?.pakistani ? 'Yes' : 'No'}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Bank Name:</label>
                                <p>{data?.bankDetails?.bankName}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Account Number:</label>
                                <p>{data?.bankDetails?.accountNumber}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Account Title:</label>
                                <p>{data?.bankDetails?.accountTitle}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">IBAN Number:</label>
                                <p>{data?.bankDetails?.ibanNumber}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Status:</label>
                                <p>{data.status}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Available Status:</label>
                                <p>{data?.availableStatus ? 'Available' : 'Not Available'}</p>
                            </div>

                            <div className="form-group">
                                <label className="title-color">Payment Status:</label>
                                <p>{data?.paymentStatus ? 'Paid' : 'Not Paid'}</p>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className="title-color">CNIC Front Picture:</label>
                                <img src={data.cnicFrontPic} alt="CNIC Front" style={{ maxWidth: '200px', height: '300px' }} />
                            </div>

                            <div className="form-group">
                                <label className="title-color">CNIC Back Picture:</label>
                                <img src={data.cnicBackPic} alt="CNIC Back" style={{ maxWidth: '200px', height: '300px' }} />
                            </div>

                            <div className="form-group">
                                <label className="title-color">License Document Picture:</label>
                                <img src={data.licenseDocsPic} alt="License Document" style={{ maxWidth: '200px', height: '300px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RiderDetailPage;
