import React, { useEffect, useState } from 'react';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import $, { data } from 'jquery';
import { Modal, message } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchBanner, deleteBanner } from '../../store/bannerSlice';

const Users = () => {
    const dispatch = useDispatch();
    const [coupons, setCoupons] = useState([]);
    const [loading, setLoading] = useState(true);
    const [approvedStates, setApprovedStates] = useState([]);

    useEffect(() => {
        const fetchCoupons = async () => {
            try {
                const response = await fetch('http://5.78.109.151:7000/common/coupons');
                const data = await response.json();
                console.log(data)
                setCoupons(data); // Assuming the data is an array
                setApprovedStates(data.map(coupon => coupon.approved)); // Initialize approved states
            } catch (error) {
                console.error('Error fetching coupons:', error);
                message.error('Failed to fetch coupons.');
            } finally {
                setLoading(false);
            }
        };

        fetchCoupons();
    }, []);

    useEffect(() => {
        dispatch(fetchBanner());
    }, [dispatch]);

    $(document).ready(function () {
        setTimeout(function () {
            $('#couponTable').DataTable({
                pagingType: 'full_numbers',
                pageLength: 25,
                processing: true,
                destroy: true,
            });
        }, 1000);
    });

    const toggleApproved = async (index, couponId, currentStatus) => {
        const newStatus = !approvedStates[index]; // Toggle the current state

        try {
            // Optimistically update the UI
            setApprovedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = newStatus;
                return newStates;
            });

            // Make the PUT request to update the coupon
            const response = await fetch(`http://5.78.109.151:7000/common/coupons/${couponId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ approved: newStatus }),
            });

            if (!response.ok) {
                throw new Error('Failed to update approval status');
            }

            // Optionally, handle the response if needed
            const updatedCoupon = await response.json();
            // Update state with the new coupon data if necessary
            // For now, we just rely on the optimistic update

        } catch (error) {
            console.error('Error updating approval status:', error);
            // Rollback UI changes on failure
            setApprovedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = currentStatus; // Revert to previous status
                return newStates;
            });
            message.error('Failed to update approval status.');
        }
    };

    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deleteBanner(id));
            }
        });
    };
   

    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2">
                        Coupons
                    </h2>
                </div>
                {loading ? (
                    <div className='text-center'>Loading...</div>
                ) : coupons.length > 0 ? (
                    <div className="table-responsive">
                        <table
                            id="couponTable"
                            className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                        >
                            <thead className="thead-light thead-50 text-capitalize">
                                <tr>
                                    <th className="pl-xl-5">SL</th>
                                    <th>Coupon Code</th>
                                    <th>Discount</th>
                                    <th>Expiry Date</th>
                                    <th>Approved</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {coupons.map((coupon, index) => (
                                    <tr key={coupon._id}>
                                        <td className="pl-xl-5">{index + 1}</td>
                                        <td>{coupon.code}</td>
                                        <td>{coupon.discountPercent}</td>
                                        <td>{coupon.endingDate
                                        }</td>
                                        <td className="text-center">
                                            <div style={{
                                                position: 'relative',
                                                display: 'inline-block',
                                                width: '40px',
                                                height: '20px',
                                            }}>
                                                <input
                                                    type="checkbox"
                                                    checked={approvedStates[index]}
                                                    onChange={() => toggleApproved(index, coupon._id, coupon.approved)}
                                                    style={{
                                                        opacity: 0,
                                                        width: 0,
                                                        height: 0,
                                                    }}
                                                />
                                                <div
                                                    onClick={() => toggleApproved(index, coupon._id, coupon.approved)}
                                                    style={{
                                                        position: 'absolute',
                                                        cursor: 'pointer',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        backgroundColor: approvedStates[index] ? '#2196F3' : '#ccc',
                                                        transition: '.4s',
                                                        borderRadius: '20px',
                                                    }}
                                                >
                                                    <div style={{
                                                        position: 'absolute',
                                                        content: '""',
                                                        height: '16px',
                                                        width: '16px',
                                                        left: approvedStates[index] ? '22px' : '2px',
                                                        bottom: '2px',
                                                        backgroundColor: 'white',
                                                        transition: '.4s',
                                                        borderRadius: '50%',
                                                    }}></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-center gap-2">
                                                <button
                                                    className="btn btn-outline-danger square-btn btn-sm mr-1"
                                                    onClick={() => onDelete(coupon._id)}
                                                    title="Delete"
                                                >
                                                    <i className="bi bi-trash" />
                                                </button>
                                               
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className='text-center'>No data available</div>
                )}
            </div>
        </div>
    );
};

export default Users;
