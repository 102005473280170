import React, { useEffect,useState } from 'react'
import DomChart from '../components/DomChart'
import LineChart from '../components/LineChart'
import axios from 'axios'
const Dashboard = () => {
    const [data ,setdata] =useState()
    useEffect(()=>{
      
       async function fetchflash(){
           
                try{
                    await axios.get(`http://5.78.109.151:7000/common/totalStats/stats`).then(
                        (resp) => {
                            setdata(resp.data.stats)
                            console.log(resp.data.stats)
                        })
                }catch(err){
                    console.log(err);
                }
            
        }
        
        
fetchflash()
       
    },[])
    console.log(data)
    return (
        <div>
            <main className="page-content">
                <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-4">
                    <div className="col">
                        <div className="card border shadow overflow-hidden radius-10">
                            <div className="card-body">
                                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                                    <div className="w-50">
                                        <p> Confirmed</p>
                                        <h4 className="">{data?.Canceled                                        }</h4>
                                    </div>
                                    <div className="w-50">
                                        <p className="mb-3 float-end text-success">
                                            +0% <i className="bi bi-arrow-up" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border shadow overflow-hidden radius-10">
                            <div className="card-body">
                                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                                    <div className="w-50">
                                        <p>Cooking</p>
                                        <h4 className="">{data?.cooking}</h4>
                                    </div>
                                    <div className="w-50">
                                        <p className="mb-3 float-end text-danger">
                                            -0% <i className="bi bi-arrow-down" />
                                        </p>
                                        <div id="chart2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border shadow overflow-hidden radius-10">
                            <div className="card-body">
                                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                                    <div className="">
                                        <p>Ready to deliever</p>
                                        <h4 className="">{data?.["ready for pickup"]}</h4>
                                    </div>
                                    <div className="w-24">
                                        <p className="mb-3 float-end text-success">
                                         0% <i className="bi bi-arrow-up" />
                                        </p>
                                        <div id="chart4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border shadow overflow-hidden radius-10">
                            <div className="card-body">
                                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                                    <div className="w-50">
                                        <p>Food on the way</p>
                                        <h4 className="">{data?.["picked up"]}</h4>
                                    </div>
                                    <div className="w-50">
                                        <p className="mb-3 float-end text-success">
                                            + 0% <i className="bi bi-arrow-up" />
                                        </p>
                                        <div id="chart4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-4">
                    <div className="col">
                        <div className="card border shadow overflow-hidden radius-10">
                            <div className="card-body">
                                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                                    <div className="w-50">
                                        <p> Delievered</p>
                                        <h4 className="">{data?.delivered}</h4>
                                    </div>
                                    <div className="w-50">
                                        <p className="mb-3 float-end text-success">
                                            +0% <i className="bi bi-arrow-up" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border shadow overflow-hidden radius-10">
                            <div className="card-body">
                                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                                    <div className="w-50">
                                        <p>Preparing                                        </p>
                                        <h4 className="">{data?.Preparing                                        }</h4>
                                    </div>
                                    <div className="w-50">
                                        <p className="mb-3 float-end text-danger">
                                            -0% <i className="bi bi-arrow-down" />
                                        </p>
                                        <div id="chart2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border shadow overflow-hidden radius-10">
                            <div className="card-body">
                                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                                    <div className="w-50">
                                        <p>Cancelled</p>
                                        <h4 className="">{data?.Cancelled                                        }</h4>
                                    </div>
                                    <div className="w-50">
                                        <p className="mb-3 float-end text-success">
                                            + 0% <i className="bi bi-arrow-up" />
                                        </p>
                                        <div id="chart3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border shadow overflow-hidden radius-10">
                            <div className="card-body">
                                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                                    <div className="w-50">
                                        <p>Pending</p>
                                        <h4 className="">{data?.pending                                        }</h4>
                                    </div>
                                    <div className="w-50">
                                        <p className="mb-3 float-end text-success">
                                            + 0% <i className="bi bi-arrow-up" />
                                        </p>
                                        <div id="chart4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6 d-flex">
                        <div className="card border shadow radius-10 w-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <h6 className="mb-0">Revenue</h6>
                                    <div className="fs-5 ms-auto dropdown">
                                        <div
                                            className="dropdown-toggle dropdown-toggle-nocaret cursor-pointer"
                                            data-bs-toggle="dropdown"
                                        >
                                            <i className="bi bi-three-dots" />
                                        </div>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a className="dropdown-item" href="">
                                                    Action
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="">
                                                    Another action
                                                </a>
                                            </li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="">
                                                    Something else here
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='mt-5'>
                                    <LineChart/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 d-flex">
                        <div className="card border shadow radius-10 w-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <h6 className="mb-0">By Device</h6>
                                </div>
                                <div className="row row-cols-1 row-cols-md-2 g-3 mt-2 align-items-center">
                                    <div className="col-lg-12 col-xl-12 col-xxl-12">
                                        <div className="by-device-container">
                                            
                                            <div style={{ minHeight: "313.7px" }}>
                                                <DomChart />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-xl-5 col-xxl-4 d-flex justify-content-between">
                                    <div className="">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex align-items-center justify-content-between border-0">
                                                <i className="bi bi-tablet-landscape-fill me-2 text-primary" />{" "}
                                                <span>Tablet - </span> <span>22.5%</span>
                                            </li>
                                            <li className="list-group-item d-flex align-items-center justify-content-between border-0">
                                                <i className="bi bi-phone-fill me-2 text-primary-2" />{" "}
                                                <span>Mobile - </span> <span>62.3%</span>
                                            </li>
                                            <li className="list-group-item d-flex align-items-center justify-content-between border-0">
                                                <i className="bi bi-display-fill me-2 text-primary-3" />{" "}
                                                <span>Desktop - </span> <span>15.2%</span>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                </div>
                                <div className="piechart-legend px-4">
                                                <h2 className="mb-1">0%</h2>
                                                <h6 className="mb-0">Total Visitors</h6>
                                            </div>

                            </div>
                        </div>
                    </div>
                </div>
               
                {/* <div className="row">
                    <div className="col-12 col-lg-12 col-xl-8 d-flex">
                        <div className="card border shadow radius-10 w-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <h6 className="mb-0">Recent Orders</h6>
                                    <div className="fs-5 ms-auto dropdown">
                                        <div
                                            className="dropdown-toggle dropdown-toggle-nocaret cursor-pointer"
                                            data-bs-toggle="dropdown"
                                        >
                                            <i className="bi bi-three-dots" />
                                        </div>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a className="dropdown-item" href="">
                                                    Action
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="">
                                                    Another action
                                                </a>
                                            </li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="">
                                                    Something else here
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="table-responsive mt-2">
                                    <table className="table align-middle mb-0">
                                        <thead className="table-light">
                                            <tr>
                                                <th>#ID</th>
                                                <th>Product</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                                <th>Date</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>#89742</td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div className="product-box border">
                                                            <img
                                                                src="/static/media/11.25c501eaa57abc4dc3db.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="product-info">
                                                            <h6 className="product-name mb-1">
                                                                Smart Mobile Phone
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>2</td>
                                                <td>$214</td>
                                                <td>Apr 8, 2021</td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-3 fs-6">
                                                        <a
                                                            href="javascript:"
                                                            className="text-primary"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="View detail"
                                                            aria-label="Views"
                                                        >
                                                            <i className="bi bi-eye-fill" />
                                                        </a>
                                                        <a
                                                            href="javascript:"
                                                            className="text-warning"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="Edit info"
                                                            aria-label="Edit"
                                                        >
                                                            <i className="bi bi-pencil-fill" />
                                                        </a>
                                                        <a
                                                            href="javascript:"
                                                            className="text-danger"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="Delete"
                                                            aria-label="Delete"
                                                        >
                                                            <i className="bi bi-trash-fill" />
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>#68570</td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div className="product-box border">
                                                            <img
                                                                src="/static/media/07.20535b55c817c1701204.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="product-info">
                                                            <h6 className="product-name mb-1">Sports Time Watch</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>1</td>
                                                <td>$185</td>
                                                <td>Apr 9, 2021</td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-3 fs-6">
                                                        <a
                                                            href="javascript:"
                                                            className="text-primary"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="View detail"
                                                            aria-label="Views"
                                                        >
                                                            <i className="bi bi-eye-fill" />
                                                        </a>
                                                        <a
                                                            href="javascript:"
                                                            className="text-warning"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="Edit info"
                                                            aria-label="Edit"
                                                        >
                                                            <i className="bi bi-pencil-fill" />
                                                        </a>
                                                        <a
                                                            href="javascript:"
                                                            className="text-danger"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="Delete"
                                                            aria-label="Delete"
                                                        >
                                                            <i className="bi bi-trash-fill" />
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>#38567</td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div className="product-box border">
                                                            <img
                                                                src="/static/media/17.92ec17700a061775c148.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="product-info">
                                                            <h6 className="product-name mb-1">Women Red Heals</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>3</td>
                                                <td>$356</td>
                                                <td>Apr 10, 2021</td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-3 fs-6">
                                                        <a
                                                            href="javascript:"
                                                            className="text-primary"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="View detail"
                                                            aria-label="Views"
                                                        >
                                                            <i className="bi bi-eye-fill" />
                                                        </a>
                                                        <a
                                                            href="javascript:"
                                                            className="text-warning"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="Edit info"
                                                            aria-label="Edit"
                                                        >
                                                            <i className="bi bi-pencil-fill" />
                                                        </a>
                                                        <a
                                                            href="javascript:"
                                                            className="text-danger"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="Delete"
                                                            aria-label="Delete"
                                                        >
                                                            <i className="bi bi-trash-fill" />
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>#48572</td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div className="product-box border">
                                                            <img
                                                                src="/static/media/04.33fe550fe383a3964239.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="product-info">
                                                            <h6 className="product-name mb-1">
                                                                Yellow Winter Jacket
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>1</td>
                                                <td>$149</td>
                                                <td>Apr 11, 2021</td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-3 fs-6">
                                                        <a
                                                            href="javascript:"
                                                            className="text-primary"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="View detail"
                                                            aria-label="Views"
                                                        >
                                                            <i className="bi bi-eye-fill" />
                                                        </a>
                                                        <a
                                                            href="javascript:"
                                                            className="text-warning"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="Edit info"
                                                            aria-label="Edit"
                                                        >
                                                            <i className="bi bi-pencil-fill" />
                                                        </a>
                                                        <a
                                                            href="javascript:"
                                                            className="text-danger"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="Delete"
                                                            aria-label="Delete"
                                                        >
                                                            <i className="bi bi-trash-fill" />
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>#96857</td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div className="product-box border">
                                                            <img
                                                                src="/static/media/10.cfcc59e4b862025a6090.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="product-info">
                                                            <h6 className="product-name mb-1">
                                                                Orange Micro Headphone
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>2</td>
                                                <td>$199</td>
                                                <td>Apr 15, 2021</td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-3 fs-6">
                                                        <a
                                                            href="javascript:"
                                                            className="text-primary"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="View detail"
                                                            aria-label="Views"
                                                        >
                                                            <i className="bi bi-eye-fill" />
                                                        </a>
                                                        <a
                                                            href="javascript:"
                                                            className="text-warning"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="Edit info"
                                                            aria-label="Edit"
                                                        >
                                                            <i className="bi bi-pencil-fill" />
                                                        </a>
                                                        <a
                                                            href="javascript:"
                                                            className="text-danger"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="Delete"
                                                            aria-label="Delete"
                                                        >
                                                            <i className="bi bi-trash-fill" />
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>#96857</td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div className="product-box border">
                                                            <img
                                                                src="/static/media/12.caffca74094017932d5f.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="product-info">
                                                            <h6 className="product-name mb-1">
                                                                Pro Samsung Laptop
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>1</td>
                                                <td>$699</td>
                                                <td>Apr 18, 2021</td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-3 fs-6">
                                                        <a
                                                            href="javascript:"
                                                            className="text-primary"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="View detail"
                                                            aria-label="Views"
                                                        >
                                                            <i className="bi bi-eye-fill" />
                                                        </a>
                                                        <a
                                                            href="javascript:"
                                                            className="text-warning"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="Edit info"
                                                            aria-label="Edit"
                                                        >
                                                            <i className="bi bi-pencil-fill" />
                                                        </a>
                                                        <a
                                                            href="javascript:"
                                                            className="text-danger"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title=""
                                                            data-bs-original-title="Delete"
                                                            aria-label="Delete"
                                                        >
                                                            <i className="bi bi-trash-fill" />
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-4 d-flex">
                        <div className="card border shadow radius-10 w-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <h6 className="mb-0">Sales By Country</h6>
                                    <div className="fs-5 ms-auto dropdown">
                                        <div
                                            className="dropdown-toggle dropdown-toggle-nocaret cursor-pointer"
                                            data-bs-toggle="dropdown"
                                        >
                                            <i className="bi bi-three-dots" />
                                        </div>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a className="dropdown-item" href="">
                                                    Action
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="">
                                                    Another action
                                                </a>
                                            </li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="">
                                                    Something else here
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div id="geographic-map" className="mt-2" />
                                <div className="traffic-widget">
                                    <div className="progress-wrapper mb-3">
                                        <p className="mb-1">
                                            United States <span className="float-end">$2.5K</span>
                                        </p>
                                        <div className="progress rounded-0" style={{ height: 6 }}>
                                            <div
                                                className="progress-bar bg-primary"
                                                role="progressbar"
                                                style={{ width: "75%" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="progress-wrapper mb-3">
                                        <p className="mb-1">
                                            Russia <span className="float-end">$4.5K</span>
                                        </p>
                                        <div className="progress rounded-0" style={{ height: 6 }}>
                                            <div
                                                className="progress-bar bg-primary"
                                                role="progressbar"
                                                style={{ width: "55%" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="progress-wrapper mb-0">
                                        <p className="mb-1">
                                            Australia <span className="float-end">$8.5K</span>
                                        </p>
                                        <div className="progress rounded-0" style={{ height: 6 }}>
                                            <div
                                                className="progress-bar bg-primary"
                                                role="progressbar"
                                                style={{ width: "80%" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </main>


        </div>
    )
}

export default Dashboard
